import { graphql, useStaticQuery } from 'gatsby';

export const useMediaQuery = () => {
  const { wordpressPage } = useStaticQuery(
    graphql`
      query MediaQuery {
        wordpressPage(wordpress_id: { eq: 120 }) {
          acf {
            pl {
              content
            }
            en {
              content
            }
            gallery {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return wordpressPage.acf;
};
