import React from 'react';
import Img from 'gatsby-image';
import PageTemplate from 'templates/PageTemplate';
import { Helmet } from 'react-helmet';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import {
  ContainerItems,
  ContainerItemsRow,
  Item,
  ContainerLinksWrapper,
} from 'components/ContainerItems/ContainerItems';
import { useIntl } from 'gatsby-plugin-intl';
import { useMediaQuery } from 'hooks/use-media-query';
import { useImageQuery } from '../hooks/use-image-query';
import HeroImage from '../components/HeroImage/HeroImage';
import { HeroSectionImage } from '../components/HeroImage/HeroImageStyles';
import * as Styled from '../components/Quote/QuoteStyles';
import QuoteIcon from '../assets/quote.svg';
import { ContentRow, ContentWrapper } from '../components/SectionContent/SectionContentStyles';

const Media: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const mediaData = useMediaQuery();
  const dataImage = useImageQuery();
  const { fluid } = dataImage.media_bg.localFile.childImageSharp;
  const { gallery } = mediaData;
  const { content } = mediaData[locale];

  return (
    <PageTemplate>
      <HeroImage>
        <HeroSectionImage>
          <Img fluid={fluid} />
        </HeroSectionImage>
        <Styled.QuoteWrapper>
          <QuoteIcon />
          <Styled.QuoteContent notAnimate>{formatMessage({ id: 'quote.title' })}</Styled.QuoteContent>
        </Styled.QuoteWrapper>
      </HeroImage>
      <ContentWrapper>
        <ContentRow top>
          <Helmet htmlAttributes={{ lang: `${locale}` }}>
            <title>Agnieszka Kazmierczyk - Media o mnie</title>
            <meta name='description' content='Some content.' />
          </Helmet>
          <SectionHeading>{formatMessage({ id: 'media.title' })}</SectionHeading>
          <ContainerItems>
            <ContainerItemsRow>
              {gallery.map((el, index: number) => {
                const { fluid } = el.localFile.childImageSharp;
                return (
                  <Item key={index}>
                    <Img fluid={fluid} />
                  </Item>
                );
              })}
            </ContainerItemsRow>
            {content && <ContainerLinksWrapper dangerouslySetInnerHTML={{ __html: content }} />}
          </ContainerItems>
        </ContentRow>
      </ContentWrapper>
    </PageTemplate>
  );
};

export default Media;
